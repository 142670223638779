import React from 'react';
import Header from '../../components/Header';
import CadastroContent from '../../components/CadastroContent';
import Welcome from '../../components/Welcome';
import WhyChoose from '../../components/WhyChoose';

const Home: React.FC = () => (
  <>
    {/* <Header />
    <Welcome />
    <WhyChoose /> */}
    <br />
    <br />
    <h1>Site em Construção</h1>
  </>
);

export default Home;
